<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{
    showErrors: (showErrors$ | async) || false,
    tinymceEnabled: tinymceEnabled$ | async,
    errors: (errors$ | async) || []
  }"
>
</ng-container>

<ng-template
  #template
  let-showErrors="showErrors"
  let-errors="errors"
  let-tinymceEnabled="tinymceEnabled"
  let-menuMode="menuMode"
  let-pageMode="pageMode"
>
  <form flexFill [formGroup]="form">
    <input
      title="File Input"
      type="file"
      accept="image/png, image/jpeg"
      class="d-none"
      #fileInput
      (change)="fileSelectedHandler(angularEditor, $event, fileInput)"
    />
    <div flexLayout="column" flexLayoutGap="1rem" class="container">
      <div flexLayout="row" flexLayoutGap="2rem" flexLayoutAlign="space-between center">
        <div flexLayout="column">
          <div mat-dialog-title>User's Bio</div>
          <div></div>
        </div>
      </div>

      <div flexLayout="column" flexLayoutGap="2rem" class="sub-container">
        <div flexLayout="row" class="editorContainer">
          @if (tinymceEnabled) {
            <editor
              #angularEditor
              id="templateEditor"
              [apiKey]="tinyMceApiKey"
              flex="100"
              formControlName="content"
              [init]="tinyConfig"
            ></editor>
          } @else {
            <div flexLayout="row" formGroupName="file">
              <mat-form-field flex="60" class="d-none">
                <mat-label>Selected File Name</mat-label>
                <input title="File Name" matInput type="text" formControlName="fileName" />
              </mat-form-field>
              <button type="button" mat-raised-button color="primary" (click)="fileInput.click()">
                Insert Image at cursor position
              </button>
            </div>
            <angular-editor
              #angularEditor
              flex="100"
              id="templateEditor"
              formControlName="content"
              [config]="config"
              (paste)="onPaste($event)"
              (click)="saveSelection()"
            >
            </angular-editor>
          }
        </div>
      </div>

      <div flexLayout="row" flexLayoutAlign="end end" flexLayoutGap="2rem" class="sub-container">
        <div flexLayout="row">
          <button mat-flat-button mat-dialog-close>Cancel</button>
        </div>
        <div flexLayout="row">
          <button type="submit" mat-flat-button color="primary" (click)="saveChanges()">
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</ng-template>
