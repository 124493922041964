/* eslint-disable no-restricted-syntax */
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel
} from "@microsoft/signalr";
import { Select, Store } from "@ngxs/store";
import { ApplicationState, EventAggregator } from "@vp/data-access/application";
import {
  AssignUserEvent,
  CallLightActivatedEvent,
  CallLightDeactivatedEvent,
  CaseChatEvent,
  CaseDataChangedEvent,
  CaseStatusChangedEvent,
  CaseUpdatedEvent,
  CommunicationEvent,
  DeviceCamerasUpdatedEvent,
  DeviceConnectionChangedEvent,
  DeviceMicrophonesUpdatedEvent,
  DeviceNetworkInterfacesUpdatedEvent,
  DevicePowerStatusUpdatedEvent,
  DeviceSirenStatusChangedEvent,
  DeviceSpeakersUpdatedEvent,
  DeviceStethoscopeUpdatedEvent,
  EventBase,
  MessageToPatientEvent,
  MovementInRoomDetectedEvent,
  NewCaseEvent,
  PacMessagesAdded,
  PacMessagesRemoved,
  RealTimeNotification,
  SignalrMethods,
  UnassignUserEvent,
  User,
  UserChangedEvent,
  ZoomWebhookEvent
} from "@vp/models";
import { AuthenticationService } from "@vp/shared/authentication";
import { IS_IVY_API } from "@vp/shared/guards";
import { LoggerService } from "@vp/shared/logger-service";
import { filterNullMap } from "@vp/shared/operators";
import { API_BASE_URL } from "@vp/shared/tokens";
import { ExtendsClass } from "@vp/shared/utilities";
import { BehaviorSubject, Observable, combineLatest, firstValueFrom, from, of } from "rxjs";
import { catchError, concatMap, filter, map, mergeMap, switchMap, take, tap } from "rxjs/operators";
import * as SignalRStateActions from "./state/signal-r-state.actions";
import { SignalRState } from "./state/signal-r.state";
export interface SignalRConnectionInfo {
  url: string;
  accessToken: string;
}

export interface EventHandlerMap {
  method: string;
  event: any;
}

@Injectable({
  providedIn: "root"
})
export class SignalRApiService {
  @Select(ApplicationState.loggedInUser) loggedInUser$!: Observable<User | null>;

  private hubConnection!: HubConnection;
  private notifications = new BehaviorSubject<RealTimeNotification[]>([]);

  // TODO: This stuff should be provided as an injection token via module configuiration
  // i.e. forChild, forRoot, etc
  private clientMethodsToSubscribe: EventHandlerMap[] = [
    {
      method: SignalrMethods.newCaseSubmitted,
      event: NewCaseEvent
    },
    {
      method: SignalrMethods.newCaseChat,
      event: CaseChatEvent
    },
    {
      method: SignalrMethods.newAssignUser,
      event: AssignUserEvent
    },
    {
      method: SignalrMethods.userUnassigned,
      event: UnassignUserEvent
    },
    {
      method: SignalrMethods.updatedCase,
      event: CaseUpdatedEvent
    },
    {
      method: SignalrMethods.callLightActivated,
      event: CallLightActivatedEvent
    },
    {
      method: SignalrMethods.callLightDeactivated,
      event: CallLightDeactivatedEvent
    },
    {
      method: SignalrMethods.sirenStatusChanged,
      event: DeviceSirenStatusChangedEvent
    },
    {
      method: SignalrMethods.movementInRoomDetected,
      event: MovementInRoomDetectedEvent
    },
    {
      method: SignalrMethods.deviceConnectionChanged,
      event: DeviceConnectionChangedEvent
    },
    {
      method: SignalrMethods.deviceCamerasUpdated,
      event: DeviceCamerasUpdatedEvent
    },
    {
      method: SignalrMethods.deviceMicrophonesUpdated,
      event: DeviceMicrophonesUpdatedEvent
    },
    {
      method: SignalrMethods.deviceSpeakersUpdated,
      event: DeviceSpeakersUpdatedEvent
    },
    {
      method: SignalrMethods.deviceNetworkInterfacesUpdated,
      event: DeviceNetworkInterfacesUpdatedEvent
    },
    {
      method: SignalrMethods.deviceStethoscopesUpdated,
      event: DeviceStethoscopeUpdatedEvent
    },
    {
      method: SignalrMethods.devicePowerStatusUpdated,
      event: DevicePowerStatusUpdatedEvent
    },
    {
      method: SignalrMethods.caseDataChanged,
      event: CaseDataChangedEvent
    },
    {
      method: SignalrMethods.userChanged,
      event: UserChangedEvent
    },
    {
      method: SignalrMethods.interactiveSessionStarted,
      event: ZoomWebhookEvent
    },
    {
      method: SignalrMethods.interactiveSessionEnded,
      event: ZoomWebhookEvent
    },
    {
      method: SignalrMethods.messageToPatient,
      event: MessageToPatientEvent
    },
    {
      method: SignalrMethods.caseStatusChanged,
      event: CaseStatusChangedEvent
    },
    {
      method: SignalrMethods.communicationChanged,
      event: CommunicationEvent
    },
    {
      method: SignalrMethods.pacMessagesAdded,
      event: PacMessagesAdded
    },
    {
      method: SignalrMethods.pacMessagesRemoved,
      event: PacMessagesRemoved
    }
  ];

  constructor(
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    @Inject(IS_IVY_API) private readonly isIvyApi: boolean,
    private readonly authenticationService: AuthenticationService,
    private readonly eventAggregrator: EventAggregator,
    private readonly store: Store,
    private readonly _http: HttpClient,
    private readonly logger: LoggerService
  ) {}

  private signalRAccessToken!: string;

  refreshToken(userId: string): Promise<string> {
    const signalRAccessToken$ = this.isAppTokenExpired().pipe(
      take(1),
      concatMap(appTokenExpired => {
        const signalRTokenExpired = tokenExpired(this.signalRAccessToken);
        const isBrowserOnline = this.store.selectSnapshot(ApplicationState.isBrowserOnline);
        if (!signalRTokenExpired || !isBrowserOnline || appTokenExpired) {
          return of(this.signalRAccessToken);
        }
        return this.authenticationService.isLoggedIn$().pipe(
          take(1),
          concatMap(authenticatedResult => {
            if (authenticatedResult.isAuthenticated) {
              return this.getSignalRConnection(userId).pipe(
                map(connection => connection.accessToken),
                tap(accessToken => {
                  this.signalRAccessToken = accessToken;
                  console.log("signalR access token renewed");
                }),
                catchError(err => {
                  console.log("signalR access token renew failed, using previous token", err);
                  return of(this.signalRAccessToken);
                })
              );
            } else {
              return of(this.signalRAccessToken);
            }
          })
        );
      })
    );

    return firstValueFrom(signalRAccessToken$);
  }

  initalize = () => {
    return this.authenticationService.isLoggedIn$().pipe(
      filter(authenticatedResult => authenticatedResult.isAuthenticated === true),
      switchMap(() => this.loggedInUser$),
      filterNullMap(),
      take(1),
      mergeMap((user: User) => {
        return combineLatest([of(user), this.getSignalRConnection(user.userId)]);
      }),
      tap(([user, connection]: [User, SignalRConnectionInfo]) => {
        this.signalRAccessToken = connection.accessToken;
        this.hubConnection = new HubConnectionBuilder()
          .withUrl(connection.url, {
            accessTokenFactory: () => this.refreshToken(user.userId)
          })
          .withAutomaticReconnect({
            nextRetryDelayInMilliseconds: retryContext => {
              if (!navigator.onLine) {
                return null;
              }
              const expired = tokenExpired(this.signalRAccessToken);
              if (!expired) {
                if (retryContext.previousRetryCount < 10) {
                  return 1000;
                } else if (retryContext.previousRetryCount < 20) {
                  return 10000;
                }
                return null;
              }
              return null;
            }
          })
          .configureLogging(LogLevel.Warning)
          .build();

        this.registerConnectionEvents();
        this.registerClientMethods();
      })
    );
  };

  start(resubscribe = false): void {
    this.hubConnection
      .start()
      .then(() => {
        if (resubscribe) {
          this.resubscribeNotification();
        }
        this.logger.systemEvent(
          `${this.constructor.name}.${this.start.name}`,
          "Connected to SignalR"
        );

        this.store.dispatch(
          new SignalRStateActions.SetState({
            hubConnection: {
              state: this.hubConnection.state,
              lastUpdated: new Date(),
              connectionId: this.hubConnection.connectionId,
              receivedEvents: []
            }
          })
        );
      })
      .catch(error => {
        this.logger.errorEvent(
          `${this.constructor.name}.${this.start.name}`,
          error,
          `Error while connecting to SignalR.`
        );
        this.stop();
      });
  }

  public stop() {
    this.hubConnection?.stop();
  }

  public reconnect() {
    this.isAppTokenExpired()
      .pipe(
        take(1),
        tap(appTokenExpired => {
          const isBrowserOnline = this.store.selectSnapshot(ApplicationState.isBrowserOnline);
          if (
            !appTokenExpired &&
            this.hubConnection?.state === HubConnectionState.Disconnected &&
            isBrowserOnline
          ) {
            this.start(true);
          }
        })
      )
      .subscribe();
  }

  public resubscribeNotification() {
    if (this.hubConnection?.state === HubConnectionState.Connected) {
      const currentNotifications = this.notifications.getValue();
      this.addManyToGroup(currentNotifications).subscribe();
    }
  }

  public addToGroup = (userId: string | undefined, groupName: string) => {
    return this.addManyToGroup([
      {
        userId: userId,
        groupName: groupName
      } as RealTimeNotification
    ]);
  };

  public addManyToGroup = (notifications: RealTimeNotification[]) => {
    this.notifications.next(notifications);

    const apiUrl = `${this.apiBaseUrl}/realtime/addUserToGroup`;
    return this._http.post<boolean>(apiUrl, notifications);
  };

  public removeFromGroup = (userId: string | undefined, groupName: string) => {
    return this.removeManyFromGroup([
      {
        userId: userId,
        groupName: groupName
      } as RealTimeNotification
    ]);
  };

  public removeManyFromGroup = (notifications: RealTimeNotification[]) => {
    const withRemoved = this.notifications
      .getValue()
      .filter(n => !notifications.map(nn => nn.groupName).includes(n.groupName));

    this.notifications.next(withRemoved);

    const apiUrl = `${this.apiBaseUrl}/realtime/removeUserFromGroup`;
    return this._http.post<boolean>(apiUrl, notifications);
  };

  private logSignalrEvent(method: string, data: any) {
    const parsedData = JSON.stringify(data, null, 4);
    const hubConnection = this.store.selectSnapshot(SignalRState.getState).hubConnection;

    //rolling list of last 20 events.
    const receivedEvents = hubConnection.receivedEvents?.slice(
      //Get the last 19
      Math.max(hubConnection.receivedEvents.length - 19, 0)
    );

    //add the new one to the beginning of the list
    receivedEvents?.unshift({
      method: method,
      data: parsedData,
      eventTime: new Date()
    });

    this.store.dispatch(
      new SignalRStateActions.SetState({
        hubConnection: {
          receivedEvents: receivedEvents
        }
      })
    );
  }

  private updateConnectionState = () => {
    this.store.dispatch(
      new SignalRStateActions.SetState({
        hubConnection: {
          state: this.hubConnection.state,
          connectionId: this.hubConnection.connectionId,
          lastUpdated: new Date()
        }
      })
    );
  };

  private registerConnectionEvents = (): void => {
    const source = `${this.constructor.name}.${this.registerConnectionEvents.name}`;

    this.hubConnection.onclose((connectionEventError: Error | undefined) => {
      if (connectionEventError instanceof Error) {
        this.logger.errorEvent(
          connectionEventError,
          source,
          "An error occured and the Signal-R connection was closed."
        );
      } else {
        this.logger.systemEvent(source, "The Signal-R connection was forceably closed.");
      }

      this.updateConnectionState();

      //ensure the previous connection has been closed before starting a new one.
      if (this.hubConnection) {
        this.hubConnection.stop();
      }
    });

    this.hubConnection.onreconnecting((connectionEventError: Error | undefined) => {
      if (connectionEventError instanceof Error) {
        this.logger.errorEvent(
          connectionEventError,
          source,
          "An error occured reconnecting to signal-r hub."
        );
      }
      this.logger.systemEvent(source, "The Signal-R hub was disconnected, attempting to reconnect");
      this.updateConnectionState();
    });

    this.hubConnection.onreconnected((connectionId: string | undefined) => {
      const source = `${this.constructor.name}.${this.registerConnectionEvents.name}`;
      this.logger.systemEvent(source, "The Signal-R hub succesfully reconnected.", {
        connectionId: connectionId
      });
      this.updateConnectionState();
    });
  };

  private registerClientMethods = (): void => {
    if (this.isIvyApi) return;

    from(
      this.clientMethodsToSubscribe.map((handler: EventHandlerMap) => {
        return new Observable<{
          handler: EventHandlerMap;
          data: string;
        }>(subscriber => {
          this.hubConnection.on(handler.method, data => {
            subscriber.next({
              handler: handler,
              data: data
            });
          });

          return () => {
            this.hubConnection.off(handler.event);
          };
        });
      })
    )
      .pipe(
        mergeMap(observable => observable),
        filterNullMap()
      )
      .subscribe({
        next: args => {
          this.logSignalrEvent(args.handler.method, args.data);
          const instance = Reflect.construct(args.handler.event, [args.data]);
          if (ExtendsClass(instance, EventBase)) {
            this.eventAggregrator.emit(instance, args.handler.method);
          }
        }
      });
  };

  private getSignalRConnection = (userId: string): Observable<SignalRConnectionInfo> => {
    const apiUrl = `${this.apiBaseUrl}/realtime/negotiate`;
    return this._http.get<SignalRConnectionInfo>(apiUrl, {
      //we must pass this header so signalR can assign a UserId per connection
      //this is needed in order to add users to groups, as that UserId identifies which connection to place in a group
      headers: new HttpHeaders().set("x-ms-signalr-userid", userId)
    });
  };

  private isAppTokenExpired() {
    return this.authenticationService
      .getAccessToken()
      .pipe(map(appAccessToken => tokenExpired(appAccessToken)));
  }
}

const tokenExpired = (token: string) => {
  if (token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const expiry = JSON.parse(atob(base64)).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }
  return true;
};
