import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Tag } from "@vp/models";
import {
  defaultMultiTagSelectorState,
  MultiTagSelectorStateModel
} from "../model/multi-tag-selector-state.model";
import * as MultiTagSelectorActions from "./multi-tag-selector.action";

@State<MultiTagSelectorStateModel>({
  name: "multiTagSelector",
  defaults: defaultMultiTagSelectorState()
})
@Injectable()
export class MultiTagSelectorState {
  @Selector()
  static getSelectedFacilityTags(state: MultiTagSelectorStateModel) {
    return state.selectedFacilityTags;
  }

  @Selector([MultiTagSelectorState.getSelectedFacilityTags])
  static selectedFacilityTags(tags: Tag[]) {
    return tags;
  }

  @Selector()
  static getSelectedHubsTags(state: MultiTagSelectorStateModel) {
    return state.selectedHubsTags;
  }

  @Selector([MultiTagSelectorState.getSelectedHubsTags])
  static selectedHubsTags(tags: Tag[]) {
    return tags;
  }

  @Selector()
  public static currentState(state: MultiTagSelectorStateModel) {
    return state;
  }

  @Action(MultiTagSelectorActions.UpdateState)
  updateState(
    ctx: StateContext<MultiTagSelectorStateModel>,
    { state }: MultiTagSelectorActions.UpdateState
  ) {
    ctx.patchState(state);
  }

  @Action(MultiTagSelectorActions.RemoveSelectedFacilities)
  removeTags(
    ctx: StateContext<MultiTagSelectorStateModel>,
    action: MultiTagSelectorActions.RemoveSelectedFacilities
  ) {
    return ctx.patchState({
      selectedFacilityTags: ctx
        .getState()
        .selectedFacilityTags.filter(t => !action.tagsToAdd.includes(t))
    });
  }

  @Action(MultiTagSelectorActions.RemoveSelectedHubs)
  removeHubs(
    ctx: StateContext<MultiTagSelectorStateModel>,
    action: MultiTagSelectorActions.RemoveSelectedHubs
  ) {
    return ctx.patchState({
      selectedHubsTags: ctx.getState().selectedHubsTags.filter(t => !action.tagsToAdd.includes(t))
    });
  }

  @Action(MultiTagSelectorActions.AddSelectedFacilities)
  addFacilities(
    ctx: StateContext<MultiTagSelectorStateModel>,
    action: MultiTagSelectorActions.AddSelectedFacilities
  ) {
    const currentlySelected = ctx.getState().selectedFacilityTags;
    const toAdd = action.tagsToAdd.filter(
      t => !currentlySelected.map(t => t.tagId).includes(t.tagId)
    );

    if (toAdd.length > 0) {
      ctx.patchState({
        selectedFacilityTags: [...currentlySelected, ...toAdd]
      });
    }
  }

  @Action(MultiTagSelectorActions.AddSelectedHubs)
  addHubs(
    ctx: StateContext<MultiTagSelectorStateModel>,
    action: MultiTagSelectorActions.AddSelectedHubs
  ) {
    const currentlySelected = ctx.getState().selectedHubsTags;
    const toAdd = action.tagsToAdd.filter(
      (tag: Tag) => !currentlySelected.map(t => t.tagId).includes(tag.tagId)
    );

    if (toAdd.length > 0) {
      ctx.patchState({
        selectedHubsTags: [...currentlySelected, ...toAdd]
      });
    }
  }
}
