<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{
    tinymceEnabled: tinymceEnabled$ | async,
    disabled: disabled | async
  }"
>
</ng-container>

<ng-template #template let-tinymceEnabled="tinymceEnabled" let-disabled="disabled">
  <div class="mb-3">
    <div flexLayout="row" flexLayoutAlign="space-between end" flexLayoutGap="30px">
      @if (props.label && props.hideLabel !== true) {
        <mat-hint class="mat-hint">{{ props.label }}</mat-hint>
      }

      @if (showSnippet() && !disabled) {
        <vp-insert-snippet (insertSnippetEvent)="insertSnippet($event)"></vp-insert-snippet>
      }
    </div>
    @if (tinymceEnabled) {
      <editor
        [lib-DisableEditor]="disabled ?? false"
        [init]="tinyConfig"
        [formlyAttributes]="field"
        [formControl]="formControl"
        [apiKey]="tinyMceApiKey"
        #editor
      ></editor>
    } @else {
      <angular-editor
        [lib-DisableEditor]="disabled ?? false"
        [config]="config"
        [formlyAttributes]="field"
        [formControl]="formControl"
        #editor
        (focusout)="saveSelection()"
        (keyup)="saveSelection()"
      >
      </angular-editor>
    }
    @if (props.description && props.hideDescription !== true) {
      <mat-hint>{{ props.description }}</mat-hint>
    }
  </div>
</ng-template>
