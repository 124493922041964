import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatSelectModule } from "@angular/material/select";
import { FormlyModule } from "@ngx-formly/core";
import { NgxsModule } from "@ngxs/store";
import { OrganizationState } from "@vp/data-access/organization";
import { TagsState } from "@vp/data-access/tags";
import { FlexModule } from "@vp/shared/directives/flex";
import { ContextDisplayModule } from "@vp/shared/pipes/context-display";
import { MultiTagSelectorState } from "../state/multi-tag-selector.state";
import { FormlyMultiTagSelectorComponent } from "./formly-multi-tag-selector.component";
import { MultiTagSelectorComponent } from "./multi-tag-selector.component";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatSelectModule,
    MatDividerModule,
    MatIconModule,
    FlexModule,
    FormlyModule,
    NgxsModule.forFeature([OrganizationState, TagsState, MultiTagSelectorState]),
    ContextDisplayModule
  ],
  declarations: [MultiTagSelectorComponent, FormlyMultiTagSelectorComponent],
  exports: [MultiTagSelectorComponent, FormlyMultiTagSelectorComponent]
})
export class MultiTagSelectorModule {}
