import { Tag } from "@vp/models";

export interface MultiTagSelectorStateModel {
  selectedFacilityTags: Tag[];
  selectedHubsTags: Tag[];
}

export const defaultMultiTagSelectorState = (): MultiTagSelectorStateModel => {
  return {
    selectedFacilityTags: [],
    selectedHubsTags: []
  };
};
