import { Tag } from "@vp/models";
import { MultiTagSelectorStateModel } from "../model/multi-tag-selector-state.model";

export class UpdateState {
  public static readonly type = "[Multi Tag Selector] Update State";
  constructor(public state: Partial<MultiTagSelectorStateModel>) {}
}

export class RemoveSelectedFacilities {
  public static readonly type = "[Multi Tag Selector] Remove Facilities";
  constructor(public tagsToAdd: Tag[]) {}
}

export class RemoveSelectedHubs {
  public static readonly type = "[Multi Tag Selector] Remove Hubs";
  constructor(public tagsToAdd: Tag[]) {}
}

export class AddSelectedFacilities {
  public static readonly type = "[Multi Tag Selector] Add Facilities";
  constructor(public tagsToAdd: Tag[]) {}
}

export class AddSelectedHubs {
  public static readonly type = "[Multi Tag Selector] Add Hubs";
  constructor(public tagsToAdd: Tag[]) {}
}
