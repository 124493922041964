import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TrackByFunction,
  ViewEncapsulation
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Select, Store } from "@ngxs/store";
import { OrganizationState } from "@vp/data-access/organization";
import { TagsState } from "@vp/data-access/tags";
import { UserFilter, UserState } from "@vp/data-access/users";
import { Tag } from "@vp/models";
import { filterNullMap } from "@vp/shared/operators";
import {
  Differences,
  getStringArrayDifferences,
  getTagDifferences,
  TagGroup
} from "@vp/shared/utilities";
import * as MultiTagSelectorActions from "../state/multi-tag-selector.action";

import {
  BehaviorSubject,
  map,
  Observable,
  pairwise,
  shareReplay,
  Subject,
  takeUntil,
  withLatestFrom
} from "rxjs";

export interface TagChangedEvent {
  tagTypeFriendlyId: string;
  tags: string[];
}

@Component({
  selector: "vp-multi-tag-selector",
  templateUrl: "./multi-tag-selector.component.html",
  styleUrls: ["./multi-tag-selector.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class MultiTagSelectorComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Select(UserState.currentFilter) currentFilter$!: Observable<UserFilter>;
  @Select(TagsState.filteredGroups) filteredGroups$!: Observable<TagGroup[]>;

  @Input() selectedTags: Tag[] = [];
  @Input() tagTypes: string[] = [];
  @Input() tagGroups: TagGroup[] = [];
  @Output() tagChanged = new EventEmitter<TagChangedEvent>();

  private destroyed$ = new Subject<void>();

  private tagGroups$$ = new BehaviorSubject<TagGroup[]>([]);
  private selectedTags$$ = new BehaviorSubject<Tag[]>([]);

  public formGroup = new UntypedFormGroup({});
  public formControls: { [key: string]: UntypedFormControl } = {};
  private formChanges$ = new BehaviorSubject<SimpleChanges>({});

  selectedTags$ = this.selectedTags$$.asObservable();

  tagGroups$: Observable<TagGroup[]> = this.filteredGroups$.pipe(
    takeUntil(this.destroyed$),
    shareReplay(1)
  );

  tags$: Observable<Tag[]> = this.tagGroups$.pipe(
    map(tagGroups => flattenTagGroups(tagGroups)),
    shareReplay(1)
  );

  constructor(private readonly store: Store) {}

  ngOnInit(): void {
    this.tagTypes.forEach(tagType => {
      this.formControls[tagType] = new UntypedFormControl([]);
    });
    this.formGroup = new UntypedFormGroup(this.formControls);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.formChanges$.next(changes);
  }

  ngAfterViewInit(): void {
    this.tagGroups$$.next(
      this.tagGroups.filter(tagGroup => this.tagTypes.includes(tagGroup.tagTypeFriendlyId))
    );

    // This prevents the ngOnChanges from emitting until after the view has been initialized
    this.formChanges$.subscribe((changes: SimpleChanges) => {
      const _previous = [...(changes.selectedTags?.previousValue ?? [])];
      const _current = [...(changes.selectedTags?.currentValue ?? [])];
      this.updateSelectedTags(_previous, _current);
      this.selectedTags$$.next(_current);
    });

    // This subscription is for keeping the selected tags in sync with the current filter.
    this.currentFilter$
      .pipe(
        filterNullMap(),
        map(filter => filter.tags ?? []),
        pairwise(),
        map(([previous, current]) => getStringArrayDifferences(previous, current)),
        withLatestFrom(this.tags$),
        takeUntil(this.destroyed$)
      )
      .subscribe(([differences, tags]: [Differences, Tag[]]) => {
        if (differences.added.length > 0) {
          const tagsToAdd = tags.filter(t => differences.added.includes(t.tagId));
          if (tagsToAdd.some(t => t.tagTypeFriendlyId === "facility")) {
            this.store.dispatch(
              new MultiTagSelectorActions.AddSelectedFacilities(
                tagsToAdd.filter(t => t.tagTypeFriendlyId === "facility")
              )
            );
          }

          if (tagsToAdd.some(t => t.tagTypeFriendlyId === "hub")) {
            this.store.dispatch(
              new MultiTagSelectorActions.AddSelectedHubs(
                tagsToAdd.filter(t => t.tagTypeFriendlyId === "hub")
              )
            );
          }
        }

        if (differences.removed.length > 0) {
          const tagsToRemove = tags.filter(t => differences.removed.includes(t.tagId));
          if (tagsToRemove.some(t => t.tagTypeFriendlyId === "facility")) {
            this.store.dispatch(
              new MultiTagSelectorActions.RemoveSelectedFacilities(
                tagsToRemove.filter(t => t.tagTypeFriendlyId === "facility")
              )
            );
          }
          if (tagsToRemove.some(t => t.tagTypeFriendlyId === "hub")) {
            this.store.dispatch(
              new MultiTagSelectorActions.RemoveSelectedHubs(
                tagsToRemove.filter(t => t.tagTypeFriendlyId === "hub")
              )
            );
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  getParentGroup(tagTypeFriendlyId: string): TagGroup[] {
    return this.tagGroups.filter(tg => tg.tagTypeFriendlyId === tagTypeFriendlyId);
  }

  getSelectedGrouping(tagTypeFriendlyId: string): TagGroup[] {
    const _selectedTags = this.selectedTags$$.getValue();
    const parentTagTypes =
      this.store
        .selectSnapshot(OrganizationState.tagTypes)
        .find(tt => tt.friendlyId == tagTypeFriendlyId)
        ?.tagTypeFriendlyPathId?.split(".") ?? [];
    const parentTagTypeFriendlyId = parentTagTypes[parentTagTypes.length - 1] ?? null;
    const selectedGrouping = this.tagGroups.filter(
      tg =>
        _selectedTags.map(t => t.tagId).includes(tg.key) &&
        tg.tagTypeFriendlyId === parentTagTypeFriendlyId
    );
    return selectedGrouping;
  }

  getChildrenOfType(tagGroups: TagGroup[], tagTypeFriendlyId: string) {
    return tagGroups.filter(tg => tg.tagTypeFriendlyId === tagTypeFriendlyId);
  }

  onTagChanged(eventValue: string[], tagTypeFriendlyId: string): void {
    this.tagChanged.emit({ tagTypeFriendlyId, tags: eventValue });
  }

  trackGroupByTagId: TrackByFunction<TagGroup> = (_: number, tagGroup: TagGroup) =>
    tagGroup.tag.tagId;
  trackByTagId: TrackByFunction<Tag> = (_: number, tag: Tag) => tag.tagId;

  private updateSelectedTags(previous: Tag[], current: Tag[]): void {
    if (Object.keys(this.formControls).length === 0) return;
    const groupedTagIds = getGroupedTags(this.tagTypes, previous, current);
    Object.entries(groupedTagIds).forEach(([key, values]: [string, string[]]) => {
      this.formControls[key]?.setValue(values);
    });
  }

  private filterDataById(data: TagGroup[], id: string): TagGroup[] {
    let result: TagGroup[] = [];
    data.forEach(item => {
      if (item.tagTypeFriendlyId === id) {
        result.push(item);
      }
      if (item.children) {
        result = result.concat(this.filterDataById(item.children, id));
      }
    });
    return result;
  }
}

const getGroupedTags = (tagTypes: string[], previous: Tag[], current: Tag[]) =>
  tagTypes.reduce((acc: Record<string, string[]>, tagType: string) => {
    const diffs: Differences = getTagDifferences(previous, current, tagType);
    acc[tagType] = current
      .filter(t => !diffs.removed.includes(t.tagId) && t.tagTypeFriendlyId === tagType)
      .map(t => t.tagId);
    return acc;
  }, {});

const flattenTagGroups = (tagGroups: TagGroup[]): Tag[] => {
  let tags: Tag[] = [];
  for (const tagGroup of tagGroups) {
    tags.push(tagGroup.tag);

    if (tagGroup.children) {
      tags = [...tags, ...flattenTagGroups(tagGroup.children)];
    }
  }
  return tags;
};
