import { Override, PricingPlanOverride, ServiceCost } from "@vp/models";
import { deeperCopy, hasOwnProperty } from "@vp/shared/utilities";

export const addServiceCostPricingPlan = (
  tagData: Readonly<Record<string, unknown>>,
  planName: string
) => {
  const clone = deeperCopy(tagData);
  if (
    !hasOwnProperty(clone, "serviceCostOverrides") ||
    !Array.isArray(clone.serviceCostOverrides)
  ) {
    clone.serviceCostOverrides = [];
  }

  clone.serviceCostOverrides.push({
    isDefault: false,
    serviceCosts: [],
    planName: planName,
    specialties: []
  });

  return clone;
};

export const deletePlan = (tagData: Readonly<Record<string, unknown>>, planName: string) => {
  const clone = deeperCopy(tagData);
  if (Object.keys(clone).includes("serviceCostOverrides")) {
    if (!Array.isArray(clone.serviceCostOverrides)) {
      clone.serviceCostOverrides = [];
    }
    const overrides = clone.serviceCostOverrides as PricingPlanOverride[];
    const planIndex = overrides.findIndex((o: PricingPlanOverride) => o.planName === planName);
    if (planIndex >= 0) {
      clone.serviceCostOverrides.splice(planIndex, 1);
    } else return null;
  } else return null;
  return clone;
};

export const patchPricingPlanOverride = (
  tagData: Readonly<Record<string, unknown>>,
  planName: string,
  pricingPlanOverride: Partial<PricingPlanOverride>
) => {
  const clone = deeperCopy(tagData);
  if (Object.keys(clone).includes("serviceCostOverrides")) {
    if (!Array.isArray(clone.serviceCostOverrides)) {
      clone.serviceCostOverrides = [];
    }
    const overrides = clone.serviceCostOverrides as PricingPlanOverride[];
    // Find existing override in current tagData
    const foundIndex = overrides.findIndex(x => x.planName === planName);
    if (foundIndex >= 0) {
      if (
        pricingPlanOverride.isDefault !== undefined &&
        pricingPlanOverride.isDefault !== overrides[foundIndex].isDefault
      ) {
        overrides.forEach((item: PricingPlanOverride) => {
          if (item.planName === planName) {
            return;
          }
          item.isDefault = false;
        });
      }

      // update the override by reference using the overridePartial
      overrides[foundIndex] = {
        ...overrides[foundIndex],
        ...pricingPlanOverride
      };
    }
  } else return null;
  return clone;
};

export const patchCostOverride = (
  tagData: Readonly<Record<string, unknown>>,
  costOverrides: Override[]
) => {
  const clone = deeperCopy(tagData);
  if (!hasOwnProperty(clone, "costOverrides") || !Array.isArray(clone.costOverrides)) {
    clone.costOverrides = [];
  }
  const overrides = clone.costOverrides as Override[];

  costOverrides.forEach(costOverride => {
    const foundIndex = overrides.findIndex(o => o.caseTypeId === costOverride.caseTypeId);
    if (foundIndex >= 0) {
      overrides[foundIndex] = costOverride;
    } else {
      overrides.push(costOverride);
    }
  });
  return clone;
};

export const addSpecialty = (
  tagData: Readonly<Record<string, unknown>>,
  planName: string,
  specialtyTagId: string
) => {
  const clone = deeperCopy(tagData);
  if (Object.keys(clone).includes("serviceCostOverrides")) {
    if (!Array.isArray(clone.serviceCostOverrides)) {
      clone.serviceCostOverrides = [];
    }
    const overrides = clone.serviceCostOverrides as PricingPlanOverride[];
    const foundOverride = overrides.find(x => x.planName === planName);
    if (foundOverride) {
      foundOverride.specialties.push(specialtyTagId);
    } else return null;
  } else return null;
  return clone;
};

export const addSpecialtyTagIds = (
  tagData: Readonly<Record<string, unknown>>,
  planName: string,
  specialtyTagIds: string[]
) => {
  const clone = deeperCopy(tagData);
  if (Object.keys(clone).includes("serviceCostOverrides")) {
    if (!Array.isArray(clone.serviceCostOverrides)) {
      clone.serviceCostOverrides = [];
    }
    const overrides = clone.serviceCostOverrides as PricingPlanOverride[];
    const foundOverride = overrides.find(x => x.planName === planName);
    if (foundOverride) {
      foundOverride.specialties.push(...specialtyTagIds);
    } else return null;
  } else return null;
  return clone;
};

export const addExtraRecipients = (
  tagData: Readonly<Record<string, unknown>>,
  recipientAdded: string
) => {
  const clone = deeperCopy(tagData);
  if (Object.keys(clone).includes("addRecipients")) {
    if (!Array.isArray(clone.addRecipients)) {
      clone.addRecipients = [];
    }
    const recipients = clone.addRecipients as string[];
    recipients.push(recipientAdded);
    clone.addRecipients = recipients;
  }

  return clone;
};

export const deleteRecipient = (
  tagData: Readonly<Record<string, unknown>>,
  recipientRemoved: string
) => {
  const clone = deeperCopy(tagData);
  if (Object.keys(clone).includes("addRecipients")) {
    if (!Array.isArray(clone.addRecipients)) {
      clone.addRecipients = [];
    }
    const recipients = clone.addRecipients as string[];
    const newArray = recipients.filter(x => x !== recipientRemoved);
    clone.addRecipients = newArray;
  }
  return clone;
};

export const deleteSpecialty = (
  tagData: Readonly<Record<string, unknown>>,
  planName: string,
  specialtyTagId: string
) => {
  const clone = deeperCopy(tagData);
  if (Object.keys(clone).includes("serviceCostOverrides")) {
    if (!Array.isArray(clone.serviceCostOverrides)) {
      clone.serviceCostOverrides = [];
    }
    const overrides = clone.serviceCostOverrides as PricingPlanOverride[];
    const foundOverride = overrides.find(x => x.planName === planName);
    if (foundOverride) {
      const specialtyIndex = foundOverride.specialties.findIndex(
        specialty => specialty === specialtyTagId
      );
      foundOverride.specialties.splice(specialtyIndex, 1);
    } else return null;
  } else return null;
  return clone;
};

export const addCostOverride = (
  tagData: Readonly<Record<string, unknown>>,
  costOverride: Override
) => {
  const clone = deeperCopy(tagData);
  if (Object.keys(clone).includes("costOverrides")) {
    if (!Array.isArray(clone.costOverrides)) {
      clone.costOverrides = [];
    }
    clone.costOverrides.push(costOverride);
  } else return null;
  return clone;
};

export const editCostOverride = (
  tagData: Readonly<Record<string, unknown>>,
  costOverride: Override
): Record<string, unknown> | null => {
  const clone: Record<string, unknown> = deeperCopy(tagData);
  if (Object.keys(clone).includes("costOverrides")) {
    if (!Array.isArray(clone.costOverrides)) {
      clone.costOverrides = [];
    }
    const overrides = clone.costOverrides as Override[];
    const costOverrideIndex = overrides.findIndex(
      (o: Override) => o.caseTypeId === costOverride.caseTypeId
    );
    overrides.splice(costOverrideIndex, 1, costOverride);
  } else return null;
  return clone;
};

export const deleteCostOverride = (
  tagData: Readonly<Record<string, unknown>>,
  caseTypeId: string
): Record<string, unknown> | null => {
  const clone = deeperCopy(tagData);
  if (Object.keys(clone).includes("costOverrides")) {
    if (!Array.isArray(clone.costOverrides)) {
      clone.costOverrides = [];
    }
    const overrides = clone.costOverrides as Override[];
    const costOverrideIndex = overrides.findIndex((o: Override) => o.caseTypeId === caseTypeId);

    overrides.splice(costOverrideIndex, 1);
  } else return null;
  return clone;
};

export const addServiceCost = (
  tagData: Readonly<Record<string, unknown>>,
  planName: string,
  serviceCost: ServiceCost
): Record<string, unknown> | null => {
  const clone = deeperCopy(tagData);
  if (Object.keys(clone).includes("serviceCostOverrides")) {
    if (!Array.isArray(clone.serviceCostOverrides)) {
      clone.serviceCostOverrides = [];
    }
    const overrides = clone.serviceCostOverrides as PricingPlanOverride[];
    const foundPlan = overrides.find((o: PricingPlanOverride) => o.planName === planName);

    if (!foundPlan) return null;

    // Update service costs reference in tagData const.
    foundPlan.serviceCosts.push(serviceCost);
  } else return null;
  return clone;
};

export const editServiceCost = (
  tagData: Readonly<Record<string, unknown>>,
  planName: string,
  serviceCost: ServiceCost
): Record<string, unknown> | null => {
  const clone: Record<string, unknown> = deeperCopy(tagData);
  if (Object.keys(clone).includes("serviceCostOverrides")) {
    if (!Array.isArray(clone.serviceCostOverrides)) {
      clone.serviceCostOverrides = [];
    }
    const overrides = clone.serviceCostOverrides as PricingPlanOverride[];
    const foundPlan = overrides.find((o: PricingPlanOverride) => o.planName === planName);

    if (
      !foundPlan ||
      !Object.keys(foundPlan).includes("serviceCosts") ||
      !Array.isArray(foundPlan.serviceCosts)
    )
      return null;

    const serviceCostIndex = foundPlan.serviceCosts.findIndex(
      sc => sc.friendlyId == serviceCost.friendlyId
    );

    foundPlan.serviceCosts[serviceCostIndex] = {
      ...foundPlan.serviceCosts[serviceCostIndex],
      ...serviceCost
    };
  } else return null;
  return clone;
};

export const deleteServiceCost = (
  tagData: Readonly<Record<string, unknown>> | null | undefined,
  planName: string,
  serviceCostFriendlyId: string
): Record<string, unknown> | null => {
  const clone = deeperCopy(tagData);
  if (Object.keys(clone).includes("serviceCostOverrides")) {
    if (!Array.isArray(clone.serviceCostOverrides)) {
      clone.serviceCostOverrides = [];
    }
    const overrides = clone.serviceCostOverrides as PricingPlanOverride[];
    const foundPlan = overrides.find((o: PricingPlanOverride) => o.planName === planName);

    // Nope out if we don't have a valid service cost array
    if (
      !foundPlan ||
      !Object.keys(foundPlan).includes("serviceCosts") ||
      !Array.isArray(foundPlan.serviceCosts)
    )
      return null;

    const serviceCostIndex = foundPlan.serviceCosts.findIndex(
      sc => sc.friendlyId == serviceCostFriendlyId
    );

    foundPlan.serviceCosts.splice(serviceCostIndex, 1);
  } else return null;
  return clone;
};
